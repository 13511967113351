import React from 'react'
import styled from 'styled-components'
import Default from '../components/Layouts/Default';

const docUrl = "https://docs.google.com/document/d/1iekCjztvpX3djvk3KcpIRhmZQO9CeW3BaV022kus_eQ/pub?embedded=true";

const TermsFrame = styled.iframe`
  width: 100%;
  height: 100vh;
  width: 100%;
  border: none;
`

const TermsPage = () => <Default><TermsFrame src={docUrl} /></Default>


export default TermsPage;
